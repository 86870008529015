<template>
  <div>
    <v-app-bar flat color="transparent" class="main-appbar">
      <app-bar-nav-icon />
      <v-toolbar-title class="headline font-weight-bold"
        >Settings</v-toolbar-title
      >
    </v-app-bar>
    <v-row>
      <v-col cols="4" v-for="(item, i) in list" :key="i">
        <v-card>
          <v-card-title>
            <span class="title font-weight-light">{{ item.title }}</span>
          </v-card-title>
          <v-card-subtitle>
            {{ item.subtitle }}
          </v-card-subtitle>
          <v-card-actions>
            <v-btn color="primary " :to="item.to" text>
              {{ item.actionTxt }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import ControlsMixin from '@/utils/mixins/Controls'
import AppBarNavIcon from '@/layouts/shared/AppBarNavIcon'

export default {
  mixins: [ControlsMixin],

  components: {
    AppBarNavIcon
  },

  name: 'Dashboard',
  data() {
    return {
      list: [
        // {
        //   title: 'Version Control',
        //   subtitle: 'Manage mobile app versions.',
        //   actionTxt: 'Manage',
        //   to: { name: 'settings.version-control' }
        // },
        {
          title: 'Privacy Policy',
          subtitle: 'Update the Privacy Policy.',
          actionTxt: 'Modify',
          to: { name: 'settings.pages.privacy' }
        },
        {
          title: 'Terms of Service',
          subtitle: 'Update the Terms of service.',
          actionTxt: 'Modify',
          to: { name: 'settings.pages.terms-of-service' }
        },
        {
          title: 'FAQ',
          subtitle: 'Update the FAQ.',
          actionTxt: 'Modify',
          to: { name: 'settings.pages.faq' }
        }
      ]
    }
  }
}
</script>
