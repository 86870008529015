<template>
  <div>
    <v-app-bar flat color="transparent" class="main-appbar">
      <app-bar-nav-icon />
      <v-toolbar-title class="headline font-weight-bold">{{
        pageTitle
      }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn color="primary" :loading="form.$busy" @click="save" large
        >Save</v-btn
      >
    </v-app-bar>
    <div v-show="!loading">
      <content-editor class="my-3" v-model="form.content" />
    </div>
    <v-container v-show="loading" style="height: 400px">
      <v-row class="fill-height" align-content="center" justify="center">
        <v-col class="subtitle-1 text-center" cols="12">
          Loading Content
        </v-col>
        <v-col cols="6">
          <v-progress-linear
            color="primary accent-4"
            indeterminate
            rounded
            height="6"
          ></v-progress-linear>
        </v-col>
      </v-row>
    </v-container>
    <v-snackbar v-model="snackbar.show" right :color="snackbar.color">
      {{ snackbar.message }}
      <template v-slot:action="{ attrs }">
        <v-btn text @click="snackbar.show = false" v-bind="attrs">OK</v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
import ContentEditor from './components/ContentEditor.vue'
import Form from '@/utils/form'
import get from 'lodash/get'

export default {
  props: {
    pageTitle: {
      type: String,
      required: true
    },
    pageType: {
      type: String,
      required: true
    }
  },
  components: {
    ContentEditor
  },
  data() {
    return {
      loading: true,
      form: new Form({
        type: this.pageType,
        content: null
      }),
      snackbar: {
        show: false,
        message: null,
        color: ''
      }
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    async getData() {
      try {
        this.loading = true
        const response = await this.$api.get(`settings/pages/${this.form.type}`)
        this.form.content = get(response, 'data.data.content')
      } finally {
        this.loading = false
      }
    },
    async save() {
      try {
        this.form.$busy = true
        await this.$api.post('settings/pages', this.form.$data())
        this.showSnackbar('Update success!', 'success')
      } catch (error) {
        this.showSnackbar(error.response.data.message, 'error')
      } finally {
        this.form.$busy = false
      }
    },
    showSnackbar(message, color) {
      this.snackbar.message = message
      this.snackbar.show = true
      this.snackbar.color = color
    }
  }
}
</script>
<style scoped>
.tab-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 15px;
}
</style>
